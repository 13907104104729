import dayjs from "dayjs"

export const getOneYearFromToday = () => {
    return dayjs().subtract(1, "year").toDate()
}

export const getOneMonthFromToday = () => {
    return dayjs().subtract(1, "month").toDate()
}

export const getOneWeekFromToday = () => {
    return dayjs().subtract(1, "week").toDate()
}

export const getStartOfDay = (date: Date) => {
    return dayjs(date).startOf("day").toDate()
}

export const getEndOfDay = (date: Date) => {
    return dayjs(date).endOf("day").toDate()
}

export const getStartOfMonth = (date: Date) => {
    return dayjs(date).startOf("month").toDate()
}

export const getEndOfMonth = (date: Date) => {
    return dayjs(date).endOf("month").toDate()
}

export const getMonthBefore = (date: Date) => {
    return dayjs(date).subtract(1, "month").toDate()
}
export const getStartOfWeek = (date: Date) => {
    return dayjs(date).startOf("week").toDate()
}

export const getEndOfWeek = (date: Date) => {
    return dayjs(date).endOf("week").toDate()
}

export const formatDateWithYear = (date: Date) => {
    return dayjs(date).format("YYYY-MM-DD")
}

export const formatDateText = (date: Date) => {
    return dayjs(date).format("ddd DD MMM")
}

export const isSameDay = (date: Date, comparisonData: Date) => {
    return dayjs(date).isSame(comparisonData, "day")
}

export const getDifferenceInYear = (
    startDate: Date,
    stopDate: Date
): number => {
    return Number(dayjs(stopDate).diff(startDate, "year", true).toFixed(2))
}

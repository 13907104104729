import logo from "assets/images/cetasol-logo.png"

function LogoAndVersion() {
    return (
        <div className="flex mt-4 mb-12 mx-4">
            <div>
                <img alt="Cetasol logo" height="64" width="65" src={logo} />
            </div>

            <div className="ml-2">
                <h1 className="text-primary font-bold">iHelm</h1>
                <div className="text-black text-base ">Version 1.24</div>
            </div>
        </div>
    )
}

export default LogoAndVersion

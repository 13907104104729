import { useState } from "react"
import {
    faCalendar,
    faChartBar,
    IconDefinition,
} from "@fortawesome/free-regular-svg-icons"
import { faBalanceScale } from "@fortawesome/free-solid-svg-icons"

import { DropdownOption } from "components/Dropdown/dropdownOption"
import Dropdown from "components/Dropdown"

import LeftMenuRow from "./LeftMenuRow"
import LogoAndVersion from "./LogoAndVersion"
import Logout from "./Logout"
import StartEndDateModal from "components/Modals/StartEndDateModal"
import { Time } from "shared/enums"
import {
    getOneMonthFromToday,
    getOneWeekFromToday,
    getOneYearFromToday,
    getStartOfDay,
} from "services/dayjs"
import { StartEndDates } from "types/types"
import { languages, languageList } from "i18n/languages"
import { useTranslation } from "react-i18next"

const languageOptions: DropdownOption<string>[] = languageList.map((lang) => {
    return {
        name: lang.name,
        value: lang.code,
    }
})

interface TimeRangeOption {
    key: Time
    name: string
    icon?: IconDefinition
}

interface LeftMenuProps {
    setDates: React.Dispatch<React.SetStateAction<StartEndDates>>
    clearToken: () => void
    onClickComparison: () => void
    onClickReport: () => void
    language: string
    setLanguage: React.Dispatch<React.SetStateAction<string>>
}

function LeftMenu(props: LeftMenuProps) {
    const { t } = useTranslation()

    const vesselOptions: DropdownOption<string>[] = [
        { name: t("All vessels"), value: "" },
        { name: "Burö", value: "BURO" },
    ]

    const timeRanges: TimeRangeOption[] = [
        { key: Time.DAY, name: t("Day"), icon: faCalendar },
        { key: Time.WEEK, name: t("Week") },
        { key: Time.MONTH, name: t("Month") },
        { key: Time.YEAR, name: t("Year") },
        { key: Time.CUSTOM, name: `${t("Custom")}...` },
    ]

    const [vesselOption, setVesselOption] = useState<DropdownOption<string>>(
        vesselOptions[0]
    )
    const [selectedTimeRange, setSelectedTimeRange] = useState(
        timeRanges[0].key
    )

    const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false)

    const timeRangeChanged = (timeRange: Time) => {
        setSelectedTimeRange(timeRange)

        if (timeRange === Time.CUSTOM) {
            setShowCustomPeriodModal(true)
            return
        }

        const endDate = new Date()

        if (timeRange === Time.YEAR) {
            props.setDates({
                startDate: getOneYearFromToday(),
                endDate: endDate,
            })
            return
        }

        if (timeRange === Time.MONTH) {
            props.setDates({
                startDate: getOneMonthFromToday(),
                endDate: endDate,
            })
            return
        }

        if (timeRange === Time.WEEK) {
            props.setDates({
                startDate: getOneWeekFromToday(),
                endDate: endDate,
            })
            return
        }

        if (timeRange === Time.DAY) {
            props.setDates({
                startDate: getStartOfDay(endDate),
                endDate: endDate,
            })
            return
        }
    }

    const customTimeRangeSubmit = (startDate: Date, endDate: Date) => {        
        props.setDates({
            startDate,
            endDate,
        })
        setShowCustomPeriodModal(false)
    }

    const customTimeRangeClose = () => {
        setShowCustomPeriodModal(false)
    }

    const changeLanguage = (lang: string) => {
        if (!Object.keys(languages).includes(lang)) {
            console.error(`Language ${lang} not yet defined`)
            return
        }

        props.setLanguage(lang)
        console.log("Lang chosen: ", lang)
    }

    return (
        <div>
            {showCustomPeriodModal && (
                <StartEndDateModal
                    show={showCustomPeriodModal}
                    title={t("Show custom period")}
                    close={customTimeRangeClose}
                    onSubmit={customTimeRangeSubmit}
                    showGroupByOption={false}
                />
            )}

            <div className="bg-white flex flex-1 flex-col h-full justify-between shadow-md">
                <div className="flex flex-col mb-4">
                    <LogoAndVersion />

                    <div className="flex justify-center mb-16 mx-10">
                        <Dropdown
                            selectedValue={vesselOption.value}
                            onSelectedChanged={setVesselOption}
                            options={vesselOptions}
                        />
                    </div>

                    <div className="mb-8">
                        {timeRanges.map((timeRange) => (
                            <LeftMenuRow
                                key={timeRange.key}
                                icon={timeRange.icon}
                                text={timeRange.name}
                                isSelected={selectedTimeRange === timeRange.key}
                                clicked={() => {
                                    timeRangeChanged(timeRange.key)
                                }}
                            />
                        ))}
                    </div>

                    <LeftMenuRow
                        icon={faChartBar}
                        text={t("Create/show report")}
                        isSelected={false}
                        clicked={props.onClickReport}
                    />
                    <LeftMenuRow
                        icon={faBalanceScale}
                        text={t("Quick comparison")}
                        isSelected={false}
                        clicked={props.onClickComparison}
                    />
                </div>

                <div>
                    <div className="flex justify-center mb-16 mx-10">
                        <Dropdown
                            selectedValue={props.language}
                            onSelectedChanged={changeLanguage}
                            options={languageOptions}
                        />
                    </div>

                    <Logout clearToken={props.clearToken} />
                </div>
            </div>
        </div>
    )
}

export default LeftMenu

import ApexChart from "react-apexcharts"
import { useTranslation } from "react-i18next"

interface FuelConsumptionChartProps {
    data: { value: number; timestamp: string }[]
    groupBy: string
}

const chartOptions = {
    chart: {
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
    },
    colors: ["#3a7e80"],
    dataLabels: {
        enabled: true,
        formatter: (value: number) => {
            return Math.round(value)
        },
        offsetY: -20,
        style: {
            fontSize: "12px",
            colors: ["#3a7e80"],
        },
    },
    markers: {
        size: 4,
    },
    plotOptions: {
        bar: {
            dataLabels: {
                position: "top",
            },
        },
    },
    xaxis: {
        type: "datetime" as const,
    },
    yaxis: {
        decimalsInFloat: 0,
        forceNiceScale: true,
        min: 0,
    },
}

function FuelConsumptionChart(props: FuelConsumptionChartProps) {
    const formattedData = props.data.map((d) => {
        const newObject = { x: d.timestamp, y: d.value }
        return newObject
    })
    const { t } = useTranslation()
    const per_text = `per ${props.groupBy}`

    return (
        <div className="flex flex-col h-full">
            <div className="p-4">
                <div className="text-xl text-secondary font-bold mb-0.5">
                    {`${t("Consumed fuel")}, ${t("Total")}`}
                </div>
                <div className="text-base">
                    {`${t("Fuel consumption explanation")}, ${t(per_text)}`}
                </div>
            </div>
            <div className="h-full">
                <ApexChart
                    options={chartOptions}
                    series={[
                        {
                            name: `${t("Consumed fuel")} ${t(
                                "Liters (short)"
                            )}`,
                            data: formattedData,
                        },
                    ]}
                    type="bar"
                    height="100%"
                />
            </div>
        </div>
    )
}

export default FuelConsumptionChart

const html2pdf = require("html2pdf.js")

export function downloadPdf(element: HTMLElement, filename: string) {
    const opt = {
        margin: 10,
        filename: filename,
    }

    html2pdf().from(element).set(opt).save()
}

import { useTranslation } from "react-i18next"
import { getCurrentUser } from "../../../services/cognito"

interface logoutProps {
    clearToken: () => void
}

function Logout({ clearToken }: logoutProps) {
    const { t } = useTranslation()

    const user = getCurrentUser()
    const onLogout = () => {
        user?.signOut()
        clearToken()
    }
    return (
        <div className="flex flex-col text-primary bg-white mb-4 justify-start">
            <div className="ml-4">
                <div className="text-xl mb-4">{t("Logged in as")}:</div>
                <div className="text-base mb-4">{user?.getUsername()} </div>
            </div>
            <button
                className="mx-4 py-3 bg-white border border-primary rounded text-primary text-xl hover:bg-accent hover:text-white"
                onClick={onLogout}
            >
                {t("Log out")}
            </button>
        </div>
    )
}

export default Logout

import { ReactNode } from "react"

interface BigBoxProps {
    children: ReactNode
}

function BigBox(props: BigBoxProps) {
    return <div className="bg-white h-96 shadow-md">{props.children}</div>
}

export default BigBox

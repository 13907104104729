import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    getFuelConsumptionForTripsBy,
    getTotalFuelConsumption,
    ReportData,
} from "services/influx"
import { downloadPdf } from "services/pdf"
import { TIME_RANGE_TYPE } from "shared/constants"

const COMPARISON_SETTINGS = {
    value: 1,
    unit: TIME_RANGE_TYPE.MONTH.toLowerCase(),
}

interface useReportProps {
    vessel: string
    startDate?: Date
    endDate?: Date
    groupBy?: string
}

export function useReport(props: useReportProps) {
    const { startDate, endDate, vessel, groupBy } = props

    const [comparisonTimeRange, setComparisonTimeRange] = useState(
        TIME_RANGE_TYPE.MONTH
    )
    const [totalFuel, setTotalFuel] = useState<number>(0)
    const [fuelConsumption, setFuelConsumption] = useState<ReportData[]>([])

    // this state is only used to fetch the data and avoid an infinite loop in the useEffect
    // due to the use of callbacks while fetching influxdb.
    const [fetchedData, setFetchedData] = useState<ReportData[]>([])

    // this will be the comparisonData, formatted for the graph
    const [fuelConsumptionComparisonData, setFuelConsumptionComparisonData] =
        useState<ReportData[]>([])
    const [totalFuelComparisonData, setTotalFuelComparisonData] =
        useState<number>(0)

    const { t } = useTranslation()

    const COMPARISON_OPTIONS = [
        { name: t("Month"), value: TIME_RANGE_TYPE.MONTH },
        { name: t("Year"), value: TIME_RANGE_TYPE.YEAR },
    ]

    function getTimeRange() {
        const timeFrame = COMPARISON_OPTIONS.find(
            (c) => c.value === comparisonTimeRange
        )

        const noDataText = "(ingen data)"

        if (timeFrame == null) return noDataText

        const hasComparisonData = fuelConsumptionComparisonData.length > 0

        if (hasComparisonData) {
            return timeFrame.name.toLowerCase()
        }

        return timeFrame.name.toLowerCase() + " " + noDataText
    }

    function getComparisonPeriod(date: Date) {
        return dayjs(date)
            .subtract(
                COMPARISON_SETTINGS.value,
                comparisonTimeRange.toLowerCase()
            )
            .toDate()
    }

    function revertComparisonPeriod(date: string) {
        return dayjs(date)
            .add(COMPARISON_SETTINGS.value, comparisonTimeRange.toLowerCase())
            .toDate()
    }

    function fetchReportData(vessel: string, startDate?: Date, endDate?: Date,
        groupBy?: string) {
        if (endDate == null || startDate == null) {
            console.error("Date not specified for report")
            return
        }

        getTotalFuelConsumption(startDate, endDate, vessel, setTotalFuel)

        getFuelConsumptionForTripsBy(
            startDate,
            endDate,
            vessel,
            setFuelConsumption,
            groupBy
        )

        // comparison data
        getTotalFuelConsumption(
            getComparisonPeriod(startDate),
            getComparisonPeriod(endDate),
            vessel,
            setTotalFuelComparisonData
        )

        getFuelConsumptionForTripsBy(
            getComparisonPeriod(startDate),
            getComparisonPeriod(endDate),
            vessel,
            setFetchedData,
            groupBy
        )
    }

    function prepareComparisonData() {
        if (fetchedData.length === 0) {
            console.error("No data found for selected period")
            setFuelConsumptionComparisonData([])
            return
        }

        const updatedData: ReportData[] = []

        for (const point of fetchedData) {
            const newPoint: ReportData = {
                value: point.value,
                timestamp: revertComparisonPeriod(
                    point.timestamp
                ).toISOString(),
            }

            updatedData.push(newPoint)
        }

        setFuelConsumptionComparisonData(updatedData)
    }

    function downloadReport() {
        const element = document.getElementById("pdf")

        if (element == null) {
            console.error("Could not find PDF element")
            return
        }

        downloadPdf(element, "iHelm Report.pdf")
    }

    function getAverageFuelConsumption(
        totalFuel: number,
        numberOfDays: number
    ) {
        return Number((totalFuel / numberOfDays).toFixed(1))
    }

    // fetch report data when date or comparisonTime changes
    useEffect(() => {
        fetchReportData(vessel, startDate, endDate, groupBy)
    }, [startDate, endDate, groupBy, comparisonTimeRange])

    // prepare the comparison data to match the same date in the graph
    useEffect(() => {
        prepareComparisonData()
    }, [fetchedData])

    return {
        getTimeRange,
        getComparisonPeriod,
        setComparisonTimeRange,
        revertComparisonPeriod,
        downloadReport,
        getAverageFuelConsumption,
        totalFuel,
        fuelConsumption,
        comparisonTimeRange,
        fuelConsumptionComparisonData,
        totalFuelComparisonData,
        COMPARISON_OPTIONS,
    }
}

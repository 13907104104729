import { flux, ParameterizedQuery } from "@influxdata/influxdb-client"
import { InfluxDB } from "@influxdata/influxdb-client"
import dayjs from "dayjs"

import * as InfluxConfig from "influxdb.config.json"
import { getDifferenceInYear, isSameDay } from "./dayjs"

const influxDBClient = new InfluxDB({
    url: InfluxConfig.url,
    token: InfluxConfig.token,
})
const queryApi = influxDBClient.getQueryApi(InfluxConfig.org)

// difference in a year between two dates.
// if above said number, it will show per week or per year
// in the calendar view
const PER_WEEK_DIFFERENCE = 0.08
const PER_YEAR_DIFFERENCE = 0.7

export function getTotalFuelConsumption(
    startValue: Date,
    endDate: Date,
    vessel: string,
    callback: Function
) {
    const query = createTotalFuelConsumptionQuery(startValue, endDate, vessel)

    runCollectQuery(query, (rowObjects: any[]) => {
        callback(rowObjects[0]._value)
    })
}

export function getTotalDockFuelConsumption(
    startDate: Date,
    endDate: Date,
    vessel: string,
    callback: Function
) {
    const query = createTotalDockFuelConsumptionQuery(
        startDate,
        endDate,
        vessel
    )

    runCollectQuery(query, (rowObjects: any[]) => {
        callback(rowObjects[0]._value)
    })
}

export interface ReportData {
    value: number
    timestamp: string
}

function convertToMonthlyView(formattedObjects: ReportData[]) {
    const newObjects = []

    const firstItem = formattedObjects[0]
    let latestMonth = new Date(firstItem.timestamp).getMonth()
    let valueOfMonth = firstItem.value

    for (const point of formattedObjects.slice(1)) {
        const month = new Date(point.timestamp).getMonth()
        const isNewMonth = latestMonth !== month
        const isLastOfArray =
            formattedObjects[formattedObjects.length - 1] === point

        if (isNewMonth || isLastOfArray) {
            latestMonth = month
            newObjects.push({
                timestamp: point.timestamp,
                value: valueOfMonth + point.value,
            })
            valueOfMonth = 0
        } else {
            valueOfMonth += point.value
        }
    }

    return newObjects
}

export function getFuelConsumptionForTrips(
    startDate: Date,
    endDate: Date,
    vessel: string,
    callback: Function,
    setGroupBy: Function,
) {
    const shouldFetchDataPerTrip = isSameDay(startDate, endDate)
    const shouldFetchDataPerWeek =
        getDifferenceInYear(startDate, endDate) >= PER_WEEK_DIFFERENCE
    const shouldShowDataPerMonth = getDifferenceInYear(startDate, endDate) > PER_YEAR_DIFFERENCE

    let by = "day"
    if (shouldFetchDataPerTrip) {
        by = "trip"
    }
    if (shouldFetchDataPerWeek) {
        by = "week"
    }
    if (shouldShowDataPerMonth) {
        by = "month"
    }

    setGroupBy(by)
    getFuelConsumptionForTripsBy(
        startDate=startDate,
        endDate=endDate,
        vessel=vessel,
        callback=callback,
        by=by
    )
}

export function getFuelConsumptionForTripsBy(
    startDate: Date,
    endDate: Date,
    vessel: string,
    callback: Function,
    by?: string,
) {
    let query: ParameterizedQuery

    if (by === "trip") {
        query = createTotalFuelConsumptionsPerTripQuery(
            startDate,
            endDate,
            vessel
        )
    } else if (by === "week") {
        query = createTotalFuelConsumptionSumPerWeekQuery(
            startDate,
            endDate,
            vessel
        )
    } else {
        query = createTotalFuelConsumptionSumPerDayQuery(
            startDate,
            endDate,
            vessel
        )
    }

    runCollectQuery(query, (rowObjects: any[]) => {
        // TODO Fix in back end in the future
        const filteredObjects = rowObjects.filter((o: any) => {
            // Ignore data points that seem unreasonable
            return o._value > 0.5
        })

        const formattedObjects = filteredObjects.map((rowObject: any) => {
            return {
                value: rowObject._value,
                timestamp: rowObject._time,
            }
        })

        if (by === "month") {
            const newObjects = convertToMonthlyView(formattedObjects)
            callback(newObjects as ReportData[])
            return
        }

        callback(formattedObjects as ReportData[])
    })
}


export function getDockFuelConsumptionForTrips(
    startDate: Date,
    endDate: Date,
    vessel: string,
    callback: Function,
    setGroupBy: Function,
) {
    const shouldFetchDataPerTrip = isSameDay(startDate, endDate)
    const shouldFetchDataPerWeek =
        getDifferenceInYear(startDate, endDate) >= PER_WEEK_DIFFERENCE
    const shouldShowDataPerMonth =
        getDifferenceInYear(startDate, endDate) > PER_YEAR_DIFFERENCE

    let by = "day"
    if (shouldFetchDataPerTrip) {
        by = "trip"
    }
    if (shouldFetchDataPerWeek) {
        by = "week"
    }
    if (shouldShowDataPerMonth) {
        by = "month"
    }

    setGroupBy(by)
    getDockFuelConsumptionForTripsBy(
        startDate=startDate,
        endDate=endDate,
        vessel=vessel,
        callback=callback,
        by=by,
    )
}

export function getDockFuelConsumptionForTripsBy(
    startDate: Date,
    endDate: Date,
    vessel: string,
    callback: Function,
    by?: string,
) {
    let query: ParameterizedQuery

    if (by == "trip") {
        query = createDockFuelConsumptionsPerTripQuery(
            startDate,
            endDate,
            vessel
        )
    } else if (by == "week") {
        query = createDockFuelConsumptionSumPerWeekQuery(
            startDate,
            endDate,
            vessel
        )
    } else {
        query = createDockFuelConsumptionSumPerDayQuery(
            startDate,
            endDate,
            vessel
        )
    }

    runCollectQuery(query, (rowObjects: any[]) => {
        // TODO Fix in back end in the future
        const filteredObjects = rowObjects.filter((o: any) => {
            // Ignore data points that seem unreasonable
            return o._value > 0.5
        })

        const formattedObjects = filteredObjects.map((rowObject: any) => {
            return {
                value: rowObject._value,
                timestamp: rowObject._time,
            }
        })

        if (by == "month") {
            const newObjects = convertToMonthlyView(formattedObjects)
            callback(newObjects as ReportData[])
            return
        }

        callback(formattedObjects)
    })
}

///////////////////////////////////////////
// QUERY CREATORS
///////////////////////////////////////////

function createTotalFuelConsumptionQuery(
    startDate: Date,
    endDate: Date,
    vessel: string
) {
    return flux`
  from(bucket: "${InfluxConfig.bucket}")
    |> range(start: ${startDate}, stop: ${endDate})
    |> filter(fn: (r) =>
      r._measurement == "CAN" and
      r._field == "total_fuel" and
      r.host == ${vessel}
    )
    |> sum()`
}

function createTotalDockFuelConsumptionQuery(
    startDate: Date,
    endDate: Date,
    vessel: string
) {
    return flux`
  from(bucket: "${InfluxConfig.bucket}")
  |> range(start: ${startDate}, stop: ${endDate})
    |> filter(fn: (r) =>
      r._measurement == "CAN" and
      r._field == "docking_fuel" and
      r.host == ${vessel}
    )
    |> sum()`
}

function createTotalFuelConsumptionsPerTripQuery(
    startDate: Date,
    endDate: Date,
    vessel: string
) {
    return flux`
  from(bucket: "${InfluxConfig.bucket}")
    |> range(start: ${startDate}, stop: ${endDate})
    |> filter(fn: (r) =>
      r._measurement == "CAN" and
      r._field == "total_fuel" and
      r.host == ${vessel}
    )`
}

function createDockFuelConsumptionsPerTripQuery(
    startDate: Date,
    endDate: Date,
    vessel: string
) {
    return flux`
  from(bucket: "${InfluxConfig.bucket}")
    |> range(start: ${startDate}, stop: ${endDate})
    |> filter(fn: (r) =>
      r._measurement == "CAN" and
      r._field == "docking_fuel" and
      r.host == ${vessel}
    )`
}

function createTotalFuelConsumptionSumPerDayQuery(
    startDate: Date,
    endDate: Date,
    vessel: string
) {
    return flux`
  from(bucket: "${InfluxConfig.bucket}")
    |> range(start: ${startDate}, stop: ${endDate})
    |> filter(fn: (r) =>
      r._measurement == "CAN" and
      r._field == "total_fuel" and
      r.host == ${vessel}
    )
    |> aggregateWindow(every: 1d, fn: sum)
    `
}

function createTotalFuelConsumptionSumPerWeekQuery(
    startDate: Date,
    endDate: Date,
    vessel: string
) {
    return flux`
  from(bucket: "${InfluxConfig.bucket}")
    |> range(start: ${startDate}, stop: ${endDate})
    |> filter(fn: (r) =>
      r._measurement == "CAN" and
      r._field == "total_fuel" and
      r.host == ${vessel}
    )
    |> aggregateWindow(every: 1w, fn: sum)
    `
}

function createDockFuelConsumptionSumPerDayQuery(
    startDate: Date,
    endDate: Date,
    vessel: string
) {
    return flux`
  from(bucket: "${InfluxConfig.bucket}")
    |> range(start: ${startDate}, stop: ${endDate})
    |> filter(fn: (r) =>
      r._measurement == "CAN" and
      r._field == "docking_fuel" and
      r.host == ${vessel}
    )
    |> aggregateWindow(every: 1d, fn: sum)
    `
}

function createDockFuelConsumptionSumPerWeekQuery(
    startDate: Date,
    endDate: Date,
    vessel: string
) {
    return flux`
  from(bucket: "${InfluxConfig.bucket}")
    |> range(start: ${startDate}, stop: ${endDate})
    |> filter(fn: (r) =>
      r._measurement == "CAN" and
      r._field == "docking_fuel" and
      r.host == ${vessel}
    )
    |> aggregateWindow(every: 1w, fn: sum)
    `
}

function runCollectQuery(query: ParameterizedQuery, callback: Function) {
    queryApi
        .collectRows(query)
        .then((rows) => {
            callback(rows)
        })
        .catch(() => {
            console.error("Finished ERROR")
        })
        .finally(() => {
            console.log("Finished SUCCESS")
        })
}

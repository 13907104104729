import { useState } from "react"
import dayjs from "dayjs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import DatePicker from "react-datepicker"

import Button from "components/Button"
import Modal from "components/Modal"
import { DropdownOption } from "components/Dropdown/dropdownOption"
import Dropdown from "components/Dropdown"
import { useTranslation } from "react-i18next"


interface StartEndDateModalProps {
    title: string
    show: boolean
    close: () => void
    onSubmit: (startDate: Date, endDate: Date, groupBy: string) => void
    showGroupByOption: boolean
}

function StartEndDateModal(props: StartEndDateModalProps) {
    const { t } = useTranslation()

    const groupByOptions: DropdownOption<string>[] = [
        {
            name: t("Day"),
            value: "day"
        },
        {
            name: t("Week"),
            value: "week"
        },
        {
            name: t("Month"),
            value: "month"
        },
        {
            name: t("Trip"),
            value: "trip"
        }
    ]

    const [startDate, setStartDate] = useState(
        dayjs().startOf("month").toDate()
    )
    const [endDate, setEndDate] = useState(dayjs().endOf("month").toDate())

    const [groupByOption, setGroupByOption] = useState("day")

    function onSubmit() {
        props.onSubmit(startDate, endDate, groupByOption)
    }

    return (
        <Modal title={props.title} show={props.show} close={props.close}>
            <div className="flex mb-6">
                <div className="flex flex-col w-full mr-6">
                    <p className="text-base mb-1">{t("From")}...</p>
                    <div className="flex items-center">
                        <DatePicker
                            className="dropdown-selection"
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={(date) => {
                                if (date instanceof Date) setStartDate(date)
                            }}
                        />
                        <span className="text-xl ml-2">
                            <FontAwesomeIcon icon={faCalendar} />
                        </span>
                    </div>
                </div>

                <div className="flex flex-col w-full">
                    <p className="text-base mb-1">{t("To")}...</p>
                    <div className="flex items-center">
                        <DatePicker
                            className="dropdown-selection"
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={(date) => {
                                if (date instanceof Date) setEndDate(date)
                            }}
                        />
                        <span className="text-xl ml-2">
                            <FontAwesomeIcon icon={faCalendar} />
                        </span>
                    </div>
                </div>
            </div>

            {props.showGroupByOption ? (
                <div className="flex flex-col w-full">
                    <p className="text-base mb-1">{t("Group by")}...</p>
                    <div className="flex items-center">
                        <Dropdown
                            selectedValue={groupByOption}
                            onSelectedChanged={setGroupByOption}
                            options={groupByOptions}
                        />
                    </div>
                </div>
            ): (
                <></>
            )}
            

            <div className="flex mt-16 justify-end">
                <Button type="secondary" onClick={props.close}>
                    {t("Cancel")}
                </Button>

                <Button type="primary" extraClasses="ml-4" onClick={onSubmit}>
                    {t("Show")}
                </Button>
            </div>
        </Modal>
    )
}

export default StartEndDateModal

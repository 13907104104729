import dayjs from "dayjs"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FUEL_PRICE, TIME_RANGE_TYPE } from "shared/constants"

import Modal from "components/Modal"

import ReportBox from "./ReportBox"
import ReportChart from "./ReportChart"
import Button from "components/Button"
import Dropdown from "components/Dropdown"
import { useReport } from "hooks/useReport"
import { useTranslation } from "react-i18next"

interface ReportModalProps {
    show: boolean
    vessel: string
    startDate?: Date
    endDate?: Date
    groupBy?: string
    close: () => void
}

function ReportModal(props: ReportModalProps) {
    const { t } = useTranslation()

    const {
        getTimeRange,
        setComparisonTimeRange,
        downloadReport,
        getAverageFuelConsumption,
        totalFuel,
        fuelConsumption,
        comparisonTimeRange,
        fuelConsumptionComparisonData,
        totalFuelComparisonData,
        COMPARISON_OPTIONS,
    } = useReport({
        vessel: props.vessel,
        startDate: props.startDate,
        endDate: props.endDate,
        groupBy: props.groupBy,
    })

    const per_text = `per ${props.groupBy}`

    return (
        <Modal title="" show={props.show} close={props.close}>
            <div className="flex flex-row space-x-4 justify-start mb-1">
                <Button
                    type="secondary"
                    extraClasses="w-56 font-bold h-14"
                    onClick={downloadReport}
                >
                    <span>
                        <FontAwesomeIcon className="mr-4" icon={faDownload} />
                    </span>
                    {t("Download PDF")}
                </Button>

                <div className="relative bottom-7">
                    <p className="text-base mb-1">
                        {t("Compare with previous")}...
                    </p>
                    <Dropdown
                        options={COMPARISON_OPTIONS}
                        selectedValue={comparisonTimeRange}
                        onSelectedChanged={setComparisonTimeRange}
                    />
                </div>
            </div>
            <div className="border border-black py-4 px-8 h-a4Height w-a4Width">
                <div id="pdf">
                    <h1 className="text-xl font-bold">{t("iHelm report")}</h1>
                    <h2 className="text-lg">
                        <span className="font-bold">{t("Date")}</span>:{" "}
                        {dayjs(props.startDate).format("YYYY-MM-DD") +
                            ` ${t("To")} ` +
                            dayjs(props.endDate).format("YYYY-MM-DD")}
                    </h2>
                    <h2 className="text-lg mb-2">
                        <span className="font-bold">
                            {t("Comparison period")}
                        </span>
                        : {t("Previous")} {getTimeRange()}
                    </h2>

                    <div className="flex flex-col mb-7">
                        <h2 className="text-lg font-bold mb-2">
                            {t("Overview")}
                        </h2>
                        <div className="flex flex-row space-x-6">
                            <ReportBox
                                title={t("Consumed fuel")}
                                value={totalFuel}
                                unit={t("Liters (short)")}
                            />
                            <ReportBox
                                title={t("Fuel cost")}
                                value={totalFuel && totalFuel * FUEL_PRICE}
                                unit={t("(currency)")}
                            />
                            <ReportBox
                                title={`${t("Average consumption")} (${t(
                                    per_text
                                )})`}
                                value={getAverageFuelConsumption(
                                    totalFuel,
                                    fuelConsumption.length
                                )}
                                unit={t("Liters (short)")}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col h-80 mb-6">
                        <h2 className="text-lg font-bold mb-2">
                            {`${t("Consumed fuel")}, ${t(per_text)} (${t(
                                "Liters (short)"
                            )})`}
                        </h2>
                        <ReportChart
                            data={fuelConsumption}
                            comparisonData={fuelConsumptionComparisonData}
                        />
                    </div>

                    <h2 className="text-lg font-bold">{t("Comparisons")}</h2>
                    <div className="flex flex-col space-y-4">
                        <div className="flex">
                            <div className="w-72" />
                            <p className="w-28 ml-6 font-bold text-center">
                                {t("Current")}
                            </p>
                            <p className="w-28 ml-6 font-bold text-center">
                                {t("Previous")}
                            </p>
                        </div>

                        <div className="flex">
                            <p className="w-72 py-2 pl-4 border border-gray-500 rounded">
                                {`${t("Consumed fuel")} - ${t("Total")}`}
                            </p>
                            <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                                {totalFuel ? Math.round(totalFuel) : "--"} L
                            </p>
                            <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                                {totalFuelComparisonData
                                    ? Math.round(totalFuelComparisonData)
                                    : "--"}
                                L
                            </p>
                        </div>

                        <div className="flex">
                            <p className="w-72 py-2 pl-4 border border-gray-500 rounded">
                                {t("Fuel cost")}
                            </p>
                            <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                                {totalFuel
                                    ? Math.round(totalFuel) * FUEL_PRICE
                                    : "--"}
                                {t("(currency)")}
                            </p>
                            <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                                {totalFuelComparisonData
                                    ? Math.round(totalFuelComparisonData) *
                                      FUEL_PRICE
                                    : "--"}
                                {t("(currency)")}
                            </p>
                        </div>

                        <div className="flex">
                            <p className="w-72 py-2 pl-4 border border-gray-500 rounded">
                                {`${t("Average consumption")} (${t(
                                    per_text
                                )}) `}
                            </p>
                            <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                                {getAverageFuelConsumption(
                                    totalFuel,
                                    fuelConsumption.length
                                ) + ` ${t("Liters (short)")}`}
                            </p>
                            <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                                {getAverageFuelConsumption(
                                    totalFuelComparisonData,
                                    fuelConsumptionComparisonData.length
                                ) + ` ${t("Liters (short)")}`}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ReportModal

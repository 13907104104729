interface ReportBoxProps {
    title: string
    value?: number
    unit: string
}

function ReportBox({ title, value, unit }: ReportBoxProps) {
    let valueString =
        value === undefined ? `-- ${unit}` : `${Math.round(value)}`

    return (
        <div className="bg-white w-40 h-32 p-2 shadow-md relative">
            <h3 className="text-sm font-bold mb-8">{title}</h3>
            <p className="text-lg font-bold text-right absolute right-2 bottom-10">
                {valueString} <span className="text-sm">{unit}</span>
            </p>
        </div>
    )
}

export default ReportBox

interface Box {
    title: string
    description: string
    total: number
    valuetype: string
    selected: boolean
    onClick: () => void
}

function Box(props: Box) {
    const { title, description, total, valuetype, selected, onClick } = props

    // const selectedClass =
    //   position === "top" ? "mb-0 pb-6 border" : "mt-0 pt-6 border";

    const getStringValue = (value: number) =>
        Math.abs(value).toLocaleString("sv", {
            maximumFractionDigits: valuetype === "L" ? 1 : 0,
        })

    const totalString = getStringValue(total)

    const classes = selected ? "border-box border-4 border-primary" : ""

    return (
        <div className={`bg-white w-68 ${classes}`} onClick={onClick}>
            <div className="p-4">
                <div className="text-xl text-secondary font-bold mb-0.5">
                    {title}
                </div>
                <div className="text-base mb-12">{description}</div>

                {/*
        <div className={`text-${difference <= 0 ? 'accent' : 'danger'}  text-xl font-bold text-right mr-4`}>
          <FontAwesomeIcon icon={faCaretUp} className='mr-1' />
          <span>{`${differenceString} ${valuetype} ${difference <= 0 ? 'sparat' : 'ödslat'}`}</span>
        </div>
        */}

                <div className="text-secondary font-bold mr-4 pt-6 text-right">
                    <span className="text-2xl">{totalString}</span>
                </div>
            </div>
        </div>
    )
}

export default Box

import dayjs from "dayjs"
import i18n from "i18n/i18n"
import { languages } from "i18n/languages"
import { useEffect, useState } from "react"
require("dayjs/locale/sv")
require("dayjs/locale/en")

const LANGUAGE_KEY = "language"

export function useLanguage() {
    const defaultLang = loadFromLocalStorage() ?? languages.en
    dayjs.locale(defaultLang)

    const [language, setLanguage] = useState(defaultLang)

    function saveToLocalStorage(language: string) {
        localStorage.setItem(LANGUAGE_KEY, language)
    }

    function loadFromLocalStorage() {
        const lang = localStorage.getItem(LANGUAGE_KEY)
        if (lang == null) return null

        console.log("language loaded from localstorage")
        return lang
    }

    useEffect(() => {
        console.log(language)
        i18n.changeLanguage(language)
        dayjs.locale(language)
        saveToLocalStorage(language)
    }, [language])

    return { language, setLanguage }
}

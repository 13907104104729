import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { languages } from "./languages";

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: languages.sv,
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
export default i18n;

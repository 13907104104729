import "./Dropdown.css"

import { DropdownOption } from "./dropdownOption"

interface DropdownProps {
    options: DropdownOption<any>[]
    selectedValue: any
    onSelectedChanged: (option: any) => void
}

function Dropdown({
    options,
    selectedValue,
    onSelectedChanged,
}: DropdownProps) {
    return (
        <select
            className="dropdown-selection"
            value={selectedValue}
            onChange={(event) => onSelectedChanged(event.target.value)}
        >
            {options.map((option, index) => (
                <option
                    className="hover:bg-accent"
                    key={index}
                    value={option.value}
                >
                    {option.name}
                </option>
            ))}
        </select>
    )
}

export default Dropdown

import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface LeftMenuRowProps {
    icon?: IconDefinition
    text: string
    isSelected: boolean
    clicked: (text: string) => void
}

const LeftMenuRow: React.FC<LeftMenuRowProps> = ({
    icon,
    text,
    isSelected,
    clicked,
}) => {
    const background = isSelected
        ? "bg-primary hover:bg-accent"
        : "bg-white hover:bg-accent"
    const fontColor = isSelected
        ? "text-white hover:text-white"
        : "text-primary hover:text-white"
    if (icon) {
        return (
            <div
                className={`${background} ${fontColor} pl-4 py-2 text-xl`}
                onClick={() => clicked(text)}
            >
                <span className="w-8 mr-4 inline-block text-center">
                    <FontAwesomeIcon icon={icon} />
                </span>
                <span>{text}</span>
            </div>
        )
    }

    return (
        <div
            className={`${background} ${fontColor} pl-4 py-2 text-xl`}
            onClick={() => clicked(text)}
        >
            <span className="ml-12">{text}</span>
        </div>
    )
}

export default LeftMenuRow

import { useState, useEffect } from "react"
import {
    CognitoAccessToken,
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
} from "amazon-cognito-identity-js"
import { isProduction } from "./env"

if (isProduction) {
    console.log("PRODUCTION deployment")
} else {
    console.log("TEST deployment")
}

const userPoolInfo = isProduction
    ? {
          clientId: "17iap0j5sts1ef2msk8505g4ov",
          userPoolId: "eu-west-1_cn5HICiGW",
      }
    : {
          clientId: "ckt062vkqoos4r31rvfdnqqq5",
          userPoolId: "eu-west-1_MAAHbYrbC",
      }

function getStorageValue(): CognitoAccessToken | null {
    const saved = localStorage.getItem("token")
    if (saved === null) {
        return null
    }
    return JSON.parse(saved)
}

// TODO: use cognito storage instead of custom storage:
// https://stackoverflow.com/questions/68180593/how-to-get-aws-cognito-accesstoken-without-the-method-getsession
export function useTokenStorage(): [
    CognitoAccessToken | null,
    React.Dispatch<React.SetStateAction<CognitoAccessToken | null>>
] {
    const [token, setToken] = useState(() => {
        return getStorageValue()
    })

    useEffect(() => {
        localStorage.setItem("token", JSON.stringify(token))
    }, [token])

    return [token, setToken]
}

export function isTokenValid(token: CognitoAccessToken | null): boolean {
    // TODO: check whether token has expired
    return token !== null
}

interface userValues {
    email: string
    password: string
}

function cognitoUserPool(): CognitoUserPool {
    return new CognitoUserPool({
        UserPoolId: userPoolInfo.userPoolId,
        ClientId: userPoolInfo.clientId,
    })
}

export function loginCognitoUser(
    values: userValues
): Promise<CognitoUserSession> {
    const userPool = cognitoUserPool()

    const user = new CognitoUser({
        Username: values.email.toLowerCase(),
        Pool: userPool,
    })

    const authenticationData = {
        Username: values.email.toLowerCase(),
        Password: values.password,
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    return new Promise((resolve, reject) =>
        user.authenticateUser(authenticationDetails, {
            onSuccess: (result) => resolve(result),
            onFailure: (err) => reject(err),
        })
    )
}

export function getCurrentUser(): CognitoUser | null {
    const userPool = cognitoUserPool()
    return userPool.getCurrentUser()
}

export function isSignedIn(): boolean {
    const user = getCurrentUser()
    if (user !== null) {
        return true
    }
    return false
}

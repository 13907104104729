import StartEndDateModal from "components/Modals/StartEndDateModal"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { StartEndDates } from "types/types"
import ReportModal from "../../../components/Modals/ReportModal"

interface Props {
    showReport: boolean
    setShowReport: React.Dispatch<React.SetStateAction<boolean>>
    vessel: string
}

function Report(props: Props) {
    const { showReport, vessel, setShowReport } = props
    const [showSelectDate, setShowSelectDate] = useState(true)
    const [groupBy, setGroupBy] = useState("day")
    const [dates, setDates] = useState<StartEndDates>({
        startDate: new Date(),
        endDate: new Date(),
    })
    const { t } = useTranslation()

    const close = () => {
        setShowReport(false)
        setShowSelectDate(true)
    }

    const handleDateSubmit = (start: Date, end: Date, groupBy: string) => {
        setDates({
            startDate: start,
            endDate: end,
        })
        setGroupBy(groupBy)
        setShowSelectDate(false)
    }

    if (!showReport) return null

    return (
        <div className="max-h-full">
            {showSelectDate ? (
                <StartEndDateModal
                    show={showSelectDate}
                    title={t("Generate report")}
                    close={close}
                    onSubmit={handleDateSubmit}
                    showGroupByOption={true}
                />
            ) : (
                <ReportModal
                    show={!showSelectDate}
                    vessel={vessel}
                    startDate={dates.startDate}
                    endDate={dates.endDate}
                    groupBy={groupBy}
                    close={close}
                />
            )}
        </div>
    )
}

export default Report

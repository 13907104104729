import { useState } from "react"
import dayjs from "dayjs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"

import Button from "components/Button"
import Dropdown from "components/Dropdown"
import Modal from "components/Modal"
import { getMonthBefore, getStartOfMonth } from "services/dayjs"
import { Time } from "shared/enums"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"

interface TimeRangeModalProps {
    title: string
    show: boolean
    close: () => void
    onSubmit: (startDate: Date, endDate: Date, timeUnit: Time) => void
}

function generateMonthlyDateOptions() {
    const currentYear = new Date().getFullYear()

    const options = []

    for (let i = 1; i <= 12; i++) {
        let d = dayjs(`${currentYear}-0${i}-01`, "YYYY-MM-DD")
        options.push({
            name: d.format("MMM YYYY"),
            value: d.toDate(),
        })
    }

    return options
}

const dateOptions = generateMonthlyDateOptions()

function TimeRangeModal(props: TimeRangeModalProps) {
    const [selectedTimeUnit, setSelectedTimeUnit] = useState(Time.MONTH)
    const currentMonth = getStartOfMonth(new Date())
    const [currentDate, setCurrentDate] = useState(currentMonth)
    const [ComparisonDate, setComparisonDate] = useState(
        getMonthBefore(currentMonth)
    )
    const { t } = useTranslation()

    const timeUnitOptions = [
        { name: t("Month"), value: Time.MONTH },
        { name: t("Week"), value: Time.WEEK },
        { name: t("Day"), value: Time.DAY },
    ]

    function onSubmit() {
        props.onSubmit(currentDate, ComparisonDate, selectedTimeUnit)
    }

    function is(timeUnit: Time) {
        return timeUnit === selectedTimeUnit
    }

    return (
        <Modal title={props.title} show={props.show} close={props.close}>
            <div className="flex flex-col mb-6">
                <div className="flex flex-col mb-6">
                    <p className="text-base mb-1"> {t("Time unit")}</p>
                    <Dropdown
                        options={timeUnitOptions}
                        selectedValue={selectedTimeUnit}
                        onSelectedChanged={setSelectedTimeUnit}
                    />
                </div>

                <div className="flex">
                    <div className="flex flex-col w-full mr-6">
                        <p className="text-base mb-1">
                            {t("Current period")}...
                        </p>
                        <div className="flex items-center">
                            {is(Time.MONTH) ? (
                                <Dropdown
                                    options={dateOptions}
                                    selectedValue={currentDate}
                                    onSelectedChanged={setCurrentDate}
                                />
                            ) : (
                                <DatePicker
                                    className="dropdown-selection"
                                    dateFormat="yyyy-MM-dd"
                                    selected={currentDate}
                                    onChange={(date) => {
                                        if (date instanceof Date)
                                            setCurrentDate(date)
                                    }}
                                />
                            )}

                            <span className="text-xl ml-2">
                                <FontAwesomeIcon icon={faCalendar} />
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-full">
                        <p className="text-base mb-1">
                            {t("Comparison period")}...
                        </p>
                        <div className="flex items-center">
                            {is(Time.MONTH) ? (
                                <Dropdown
                                    options={dateOptions}
                                    selectedValue={ComparisonDate}
                                    onSelectedChanged={setComparisonDate}
                                />
                            ) : (
                                <DatePicker
                                    className="dropdown-selection"
                                    dateFormat="yyyy-MM-dd"
                                    selected={ComparisonDate}
                                    onChange={(date) => {
                                        if (date instanceof Date)
                                            setComparisonDate(date)
                                    }}
                                />
                            )}
                            <span className="text-xl ml-2">
                                <FontAwesomeIcon icon={faCalendar} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex mt-16 justify-end">
                <Button type="secondary" onClick={props.close}>
                    {t("Cancel")}
                </Button>

                <Button type="primary" extraClasses="ml-4" onClick={onSubmit}>
                    {t("Show")}
                </Button>
            </div>
        </Modal>
    )
}

export default TimeRangeModal

import { useEffect, useState } from "react"

import { FUEL_PRICE } from "shared/constants"

import {
    getTotalDockFuelConsumption,
    getTotalFuelConsumption,
} from "services/influx"

import Button from "components/Button"
import Modal from "components/Modal"
import { Time } from "shared/enums"
import {
    formatDateText,
    getEndOfDay,
    getEndOfMonth,
    getEndOfWeek,
    getStartOfDay,
    getStartOfMonth,
    getStartOfWeek,
} from "services/dayjs"
import { useTranslation } from "react-i18next"

interface ComparisonModalProps {
    show: boolean
    vessel: string
    timeUnit: Time
    firstDate: Date
    secondDate: Date
    close: () => void
}

function ComparisonModal(props: ComparisonModalProps) {
    const [firstTotalFuel, setFirstTotalFuel] = useState<number>()
    const [firstDockFuel, setFirstDockFuel] = useState<number>()

    const [secondTotalFuel, setSecondTotalFuel] = useState<number>()
    const [secondDockFuel, setSecondDockFuel] = useState<number>()

    const { t } = useTranslation()

    const calculateRange = (date: Date) => {
        const currentTimeUnit = props.timeUnit

        if (currentTimeUnit === Time.MONTH) {
            return {
                start: getStartOfMonth(date),
                end: getEndOfMonth(date),
            }
        }

        if (currentTimeUnit === Time.WEEK) {
            return {
                start: getStartOfWeek(date),
                end: getEndOfWeek(date),
            }
        }

        return {
            start: getStartOfDay(date),
            end: getEndOfDay(date),
        }
    }

    const getFormattedDates = (date: Date) => {
        const range = calculateRange(date)

        if (props.timeUnit === Time.DAY) {
            return formatDateText(range.start)
        }

        return `${formatDateText(range.start)} ${t("To")} ${formatDateText(
            range.end
        )}`
    }

    useEffect(() => {
        const firstRange = calculateRange(props.firstDate)

        const secondRange = calculateRange(props.secondDate)

        getTotalFuelConsumption(
            firstRange.start,
            firstRange.end,
            props.vessel,
            setFirstTotalFuel
        )

        getTotalFuelConsumption(
            secondRange.start,
            secondRange.end,
            props.vessel,
            setSecondTotalFuel
        )

        getTotalDockFuelConsumption(
            firstRange.start,
            firstRange.end,
            props.vessel,
            setFirstDockFuel
        )

        getTotalDockFuelConsumption(
            secondRange.start,
            secondRange.end,
            props.vessel,
            setSecondDockFuel
        )
    }, [props.firstDate, props.secondDate])

    return (
        <Modal
            title={t("Quick comparison")}
            show={props.show}
            close={props.close}
        >
            <div className="flex flex-col mb-6">
                <div className="flex mb-2">
                    <div className="w-72" />
                    <p className="w-28 ml-6 font-bold text-center">
                        {getFormattedDates(props.firstDate)}
                    </p>
                    <p className="w-28 ml-6 font-bold text-center">
                        {getFormattedDates(props.secondDate)}
                    </p>
                </div>

                <div className="flex mb-4">
                    <p className="w-72 py-2 pl-4 border border-gray-500 rounded">
                        {`${t("Consumed fuel")}, ${t("Total")}`}
                    </p>
                    <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                        {firstTotalFuel ? Math.round(firstTotalFuel) : "--"}{" "}
                        {t("Liters (short)")}
                    </p>
                    <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                        {secondTotalFuel ? Math.round(secondTotalFuel) : "--"}{" "}
                        {t("Liters (short)")}
                    </p>
                </div>

                <div className="flex mb-4">
                    <p className="w-72 py-2 pl-4 border border-gray-500 rounded">
                        {`${t("Consumed fuel")}, ${t("At dock")}`}
                    </p>
                    <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                        {firstDockFuel ? Math.round(firstDockFuel) : "--"}{" "}
                        {t("Liters (short)")}
                    </p>
                    <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                        {secondDockFuel ? Math.round(secondDockFuel) : "--"}{" "}
                        {t("Liters (short)")}
                    </p>
                </div>

                <div className="flex">
                    <p className="w-72 py-2 pl-4 border border-gray-500 rounded">
                        {t("Fuel cost")}
                    </p>
                    <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                        {firstTotalFuel
                            ? Math.round(firstTotalFuel) * FUEL_PRICE
                            : "--"}{" "}
                        {t("(currency)")}
                    </p>
                    <p className="w-32 py-2 px-4 ml-4 border border-gray-500 rounded text-center">
                        {secondTotalFuel
                            ? Math.round(secondTotalFuel) * FUEL_PRICE
                            : "--"}{" "}
                        {t("(currency)")}
                    </p>
                </div>
            </div>

            <div className="flex mt-16 justify-end">
                <Button type="primary" onClick={props.close}>
                    {t("OK")}
                </Button>
            </div>
        </Modal>
    )
}

export default ComparisonModal

const FUEL_PRICE = 5.0

const TIME_RANGE_CONTEXT = Object.freeze({
    COMPARISON: "COMPARISON",
    REPORT: "REPORT",
    CUSTOM: "CUSTOM",
})

const TIME_RANGE_TYPE = Object.freeze({
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
    YEAR: "YEAR",
    CUSTOM: "CUSTOM",
})

export { FUEL_PRICE, TIME_RANGE_CONTEXT, TIME_RANGE_TYPE }

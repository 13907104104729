import Dashboard from "components/Dashboard"
import LeftMenu from "components/LeftMenu"
import Login from "components/Login"
import { isSignedIn, useTokenStorage, isTokenValid } from "services/cognito"

import "./App.css"
import "styles/tailwind.generated.css"
import "react-datepicker/dist/react-datepicker.css"
import { useEffect, useState } from "react"
import Report from "features/report/components/Report"
import { getStartOfDay } from "services/dayjs"
import { StartEndDates } from "types/types"
import Comparison from "features/comparison/components/Comparison"
import { languages } from "i18n/languages"
import { useLanguage } from "hooks/useLanguage"

function App() {
    const [token, setToken] = useTokenStorage()
    const [currentVessel, setCurrentVessel] = useState("BURO")
    const [dates, setDates] = useState<StartEndDates>({
        startDate: getStartOfDay(new Date()),
        endDate: new Date(),
    })
    const { language, setLanguage } = useLanguage()

    const [showReport, setShowReport] = useState(false)
    const [showComparison, setShowComparison] = useState(false)

    if (!isTokenValid(token) || !isSignedIn()) {
        return (
            <div className="app h-screen">
                <Login setToken={setToken} />;
            </div>
        )
    }

    function onClickComparison() {
        setShowComparison(true)
    }

    function onClickReport() {
        setShowReport(true)
    }

    return (
        <div className="flex flex-row bg-backdrop h-screen w-auto">
            <div className="flex">
                <LeftMenu
                    setDates={setDates}
                    clearToken={() => setToken(null)}
                    onClickComparison={onClickComparison}
                    onClickReport={onClickReport}
                    language={language}
                    setLanguage={setLanguage}
                />
                <Dashboard
                    startDate={dates.startDate}
                    endDate={dates.endDate}
                    selectedVessel={currentVessel}
                />
            </div>

            <Report
                showReport={showReport}
                setShowReport={setShowReport}
                vessel={currentVessel}
            />

            <Comparison
                vessel={currentVessel}
                setShowComparison={setShowComparison}
                showComparison={showComparison}
            />
        </div>
    )
}

export default App

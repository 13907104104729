import ComparisonModal from "components/Modals/ComparisonModal"
import TimeRangeModal from "components/Modals/TimeRangeModal"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Time } from "shared/enums"
import { ComparisonDates } from "types/types"

interface Props {
    showComparison: boolean
    setShowComparison: React.Dispatch<React.SetStateAction<boolean>>
    vessel: string
}

function Comparison(props: Props) {
    const { showComparison, vessel, setShowComparison } = props
    const [timeUnit, setTimeUnit] = useState(Time.MONTH)

    const [showSelectDate, setShowSelectDate] = useState(true)
    const [dates, setDates] = useState<ComparisonDates>({
        firstDate: new Date(),
        secondDate: new Date(),
    })
    const { t } = useTranslation()

    const close = () => {
        setShowComparison(false)
        setShowSelectDate(true)
    }

    const handleDateSubmit = (first: Date, second: Date, timeUnit: Time) => {
        setDates({
            firstDate: first,
            secondDate: second,
        })
        setTimeUnit(timeUnit)
        setShowSelectDate(false)
    }

    if (!showComparison) return null

    return (
        <div>
            {showSelectDate ? (
                <TimeRangeModal
                    show={showSelectDate}
                    title={t("Quick comparison")}
                    close={close}
                    onSubmit={handleDateSubmit}
                />
            ) : (
                <ComparisonModal
                    show={!showSelectDate}
                    vessel={vessel}
                    timeUnit={timeUnit}
                    firstDate={dates.firstDate}
                    secondDate={dates.secondDate}
                    close={close}
                />
            )}
        </div>
    )
}

export default Comparison

import ApexChart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import { ReportData } from "services/influx"

interface ReportChartProps {
    data: ReportData[]
    comparisonData: ReportData[]
}

const chartOptions = {
    chart: {
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
    },
    colors: ["#6fc2bb", "#143b5c"],
    dataLabels: {
        enabled: false,
    },
    markers: {
        size: 4,
    },
    xaxis: {
        type: "datetime" as const,
    },
    yaxis: {
        decimalsInFloat: 0,
    },
}

function formatData(data: ReportData) {
    const newObject = { x: data.timestamp, y: data.value }
    return newObject
}

function ReportChart(props: ReportChartProps) {
    const formattedData = props.data.map(formatData)
    const formattedComparisonData = props.comparisonData.map(formatData)
    const { t } = useTranslation()

    return (
        <div className="h-full">
            <ApexChart
                options={chartOptions}
                series={[
                    {
                        name: t("Current period"),
                        data: formattedData,
                    },
                    {
                        name: t("Comparison period"),
                        data: formattedComparisonData,
                    },
                ]}
                type="line"
                height="100%"
            />
        </div>
    )
}

export default ReportChart

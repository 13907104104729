import { ReactNode } from "react"
import ReactModal from "react-modal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

interface ModalProps {
    children: ReactNode
    show: boolean
    title: string
    close: () => void
}

function Modal(props: ModalProps) {
    return (
        <ReactModal
            className="absolute max-h-full bg-white shadow-md p-6 overflow-hidden overflow-y-auto"
            style={{
                overlay: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
            }}
            isOpen={props.show}
            preventScroll={false}
            onRequestClose={props.close}
        >
            <div className="transform w-full h-full">
                <div className="flex justify-between relative">
                    <h1 className="text-xl font-bold mb-5">{props.title}</h1>
                    <span className="text-xl absolute top-1 right-1">
                        <FontAwesomeIcon icon={faTimes} onClick={props.close} />
                    </span>
                </div>
                {props.children}
            </div>
        </ReactModal>
    )
}

export default Modal

interface ButtonProps {
    children: React.ReactNode
    type: "primary" | "secondary"
    extraClasses?: string
    onClick?: () => void
}

function Button({ children, extraClasses, type, onClick }: ButtonProps) {
    // "primary" style per default
    let buttonTypeClasses =
        "bg-accent text-white hover:bg-primary hover:border-primary"

    if (type === "secondary") {
        buttonTypeClasses =
            "bg-white text-accent hover:bg-primary hover:border-primary hover:text-white"
    }

    return (
        <button
            className={`w-40 py-2 uppercase border border-accent rounded text-lg ${buttonTypeClasses} ${
                extraClasses ? extraClasses : ""
            }`}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default Button

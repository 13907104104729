export const languages = {
    en: "en",
    sv: "sv",
}

export const languageList = [
    {
        name: "English",
        code: languages.en,
    },
    {
        name: "Swedish",
        code: languages.sv,
    },
]

import { useEffect, useState } from "react"

import { FUEL_PRICE } from "shared/constants"

import {
    getFuelConsumptionForTrips,
    getDockFuelConsumptionForTrips,
    getTotalDockFuelConsumption,
    getTotalFuelConsumption,
} from "services/influx"

import BigBox from "components/BigBox"
import Box from "components/Box"
import FuelConsumptionChart from "components/Charts/FuelConsumptionChart"
import FuelConsumptionDockChart from "components/Charts/FuelConsumptionDockChart"
import FuelCostChart from "components/Charts/FuelCostChart"
import dayjs from "dayjs"
import { isSameDay } from "services/dayjs"
import { useTranslation } from "react-i18next"

interface DashboardProps {
    selectedVessel: string
    startDate: Date
    endDate: Date
}

interface CardInformation {
    title: string
    description: string
    total: number
    valuetype: string
    chart: (props: any) => JSX.Element
    chartData: any[]
    groupBy: string
}

const Dashboard = (props: DashboardProps) => {
    const { t } = useTranslation()

    const [totalFuelConsumption, setTotalFuelConsumption] = useState(0)
    const [totalFuelConsumptionCost, setTotalFuelConsumptionCost] = useState(0)
    const [totalDockFuelConsumption, setTotalDockFuelConsumption] = useState(0)

    const [groupBy, setGroupBy] = useState("trip")
    const [tripsFuelConsumption, setTripsFuelConsumption] = useState([])
    const [tripsFuelConsumptionDock, setTripsFuelConsumptionDock] = useState([])

    const [current, setCurrent] = useState(0)

    // just add new objects to this array to add new cards
    const components: CardInformation[] = [
        {
            title: `${t("Consumed fuel")}, ${t("Total")}`,
            description: t("Liters"),
            total: totalFuelConsumption,
            valuetype: t("Liters (short)"),
            chart: FuelConsumptionChart,
            chartData: tripsFuelConsumption,
            groupBy: groupBy,
        },
        {
            title: `${t("Consumed fuel")}, ${t("At dock")}`,
            description: t("Liters"),
            total: totalDockFuelConsumption,
            valuetype: t("Liters (short)"),
            chart: FuelConsumptionDockChart,
            chartData: tripsFuelConsumptionDock,
            groupBy: groupBy,
        },
        {
            title: t("Fuel cost"),
            description: `${t("Total")}, ${t("(currency)")}`,
            total: totalFuelConsumptionCost,
            valuetype: t("(currency)"),
            chart: FuelCostChart,
            chartData: tripsFuelConsumption.map((d: any) => {
                return {
                    value: d.value * FUEL_PRICE,
                    timestamp: d.timestamp,
                }
            }),
            groupBy: groupBy,
        },
    ]

    function fetchFuelConsumptionForTrips() {
        getFuelConsumptionForTrips(
            props.startDate,
            props.endDate,
            props.selectedVessel,
            setTripsFuelConsumption,
            setGroupBy,
        )
    }

    function fetchDockFuelConsumptionForTrips() {
        getDockFuelConsumptionForTrips(
            props.startDate,
            props.endDate,
            props.selectedVessel,
            setTripsFuelConsumptionDock,
            setGroupBy,
        )
    }

    useEffect(() => {
        getTotalFuelConsumption(
            props.startDate,
            props.endDate,
            props.selectedVessel,
            setTotalFuelConsumption
        )
        getTotalDockFuelConsumption(
            props.startDate,
            props.endDate,
            props.selectedVessel,
            setTotalDockFuelConsumption
        )

        fetchFuelConsumptionForTrips()
        fetchDockFuelConsumptionForTrips()
    }, [props.startDate, props.endDate, props.selectedVessel])

    useEffect(() => {
        setTotalFuelConsumptionCost(totalFuelConsumption * FUEL_PRICE)
    }, [totalFuelConsumption])

    const getCurrentGraph = () => {
        const currentComponent = components.find(
            (component, index) => index === current
        )

        if (currentComponent == null) {
            console.error("Could not find component, something went wrong.")
            return null
        }

        return (
            <currentComponent.chart
                data={currentComponent.chartData}
                groupBy={currentComponent.groupBy}
            ></currentComponent.chart>
        )
    }

    return (
        <div className="flex flex-col m-6 space-y-6">
            <BigBox>{getCurrentGraph()}</BigBox>

            <div className="grid grid-cols-2 lg:grid-cols-3 align-center justify-center place-content-center gap-4">
                {components.map((component, index) => {
                    return (
                        <Box
                            {...component}
                            onClick={() => setCurrent(index)}
                            selected={current === index}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default Dashboard

import React, { useState, useEffect } from "react"
import logo from "../../assets/images/cetasol-logo-large.png"
import { loginCognitoUser } from "../../services/cognito"
import { CognitoAccessToken } from "amazon-cognito-identity-js"

interface LoginProps {
    setToken: (value: CognitoAccessToken | null) => void
}

const Login: React.FC<LoginProps> = ({ setToken }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showLoginError, setShowLoginError] = useState(false)

    const loginUser = async () => {
        // console.log("login", email, password);
        try {
            const res = await loginCognitoUser({
                email: email,
                password: password,
            })
            // console.log('Login success. Result: ', res)
            setToken(res.getAccessToken())
        } catch (e) {
            // console.log('Login fail. Error: ', e)
            setShowLoginError(true)
        }
    }

    return (
        <div className="flex flex-1 h-full flex-col">
            <div className="mt-5 ml-5 absolute">
                <img
                    alt="Cetasol logo"
                    height="106"
                    width="303"
                    src={logo}
                    className=""
                ></img>
            </div>
            <div className="text-center flex flex-1 flex-col items-center justify-start mt-60">
                <h1 className="text-accent">Välkommen till iHelm</h1>
                <div className="text-primary text-lg font-bold mb-12">
                    En maritim innovation från{" "}
                    <span className="underline">Cetasol</span>
                </div>
                <div className="flex flex-col items-center mb-3">
                    <div className="flex text-lg text-accent items-left w-80 mb-1">
                        E-postadress:
                    </div>
                    <input
                        className="h-12 pl-4 border-2 border-accent rounded w-80 focus:outline-none focus:border-primary"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    ></input>
                </div>
                <div className="flex flex-col items-center mb-9">
                    <div className="flex text-lg text-accent items-left w-80 mb-1">
                        Lösenord:
                    </div>
                    <input
                        className="h-12 pl-4 border-2 border-accent rounded w-80 mb-3 focus:outline-none focus:border-primary"
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    ></input>
                    <div className="flex text-accent items-left w-80 mb-1 underline italic">
                        Glömt lösenordet? Klicka här
                    </div>
                </div>
                <button
                    onClick={loginUser}
                    className="px-4 py-3 bg-accent border border-primary rounded text-white text-xl w-80 mb-4 hover:bg-primary hover:text-white"
                >
                    Logga in
                </button>
                {showLoginError && (
                    <div className="text-danger text-lg font-bold">
                        Felaktig e-postadress eller lösenord
                    </div>
                )}
            </div>
        </div>
    )
}

export default Login
